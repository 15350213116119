.team {
  width: var(--container-width-lg);
  height: 60vh;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.team__content {
  width: 40%;
}

.team__content h2 {
  font-size: 50px;
  font-weight: 600;
}

.team__content p {
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
}



.team__image {
  width: 60%;
  height: 100%;
  background: url("../../assets/images/Dots.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team__image img {
  width: 75%;
  border-radius: 400px 16px 400px 400px;
}

/* tablets screens */
@media screen and (max-width: 1024px) {
  .team {
    width: var(--container-width-md);
  }
  .team__content h2 {
    font-size: 2.2rem;
    font-weight: 600;
  }

  .team {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
  }

  .team__content {
    width: 100%;
    text-align: center;
  }

  .team__image img {
    width: 70%;
  }
}

/* mobiles screens */
@media screen and (max-width: 600px) {
  .team {
    width: var(--container-width-sm);
  }
  
  .team__content h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  .team {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
  }

  .team__content {
    width: 100%;
    text-align: center;
  }

  .team__image img {
    display: none;
  }
}
