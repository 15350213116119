.about__title {
  margin-top: 7rem;
  padding: 6rem 0;
  background-color: aliceblue;
}

.about h1 {
  text-align: center;
  font-size: 3rem;
}

.about__cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 5rem;
  gap: 2rem;
}

.about__kkreative {
  width: var(--container-width-lg);
  margin: 0 auto;
  margin-top: 5rem;
}

.about__kkreative h2{
    margin-bottom: 1rem;
    font-size: 1.8rem;
    color: var(--color-primary);
}

.about__kkreative p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

