.header {
    width: 100%;
    height: 80vh;
    background-image: url("../../assets/images/headerBg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.header__info {
    margin-bottom: 7rem;
}

.header__content h3{
    font-weight: 400;
    font-size: 3rem;
}

.header__content h1 {
    font-weight: 600;
    font-size:  5rem;
    line-height: 6rem;
}

.header__content p {
    font-size: 1.5rem;
    font-weight: 400;
}

.header__buttons {
    margin-top: 1.2rem;
}

.header__buttons button:last-child:hover {
    color: #12B98E;
}

.header__buttons button:last-child {
    padding: 0.8rem 2rem;
    border-radius: 1.5rem;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    font-weight: 600;
}

