@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  text-align: none;
  text-decoration: none;
  outline: 0;
}

:root {
  --color-primary:#12b98e;
  --container-width-lg:75%;
  --container-width-md:86%;
  --container-width-sm:90%;
  --box-shadow:rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --transition:all 400ms ease
}

body {
  font-family: "Poppins", sans-serif !important;
}

.button {
  width: max-content;
  padding: 0.8rem 2rem;
  border-radius: 1.5rem;
  border: none;
  background-color: #12b98e;
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  transition: var(--transition);
}

.button:hover {
  background-color: black;
  color: #12b98e;
}


img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

a {
  color: inherit;
}