.about__card {
  width: 340px;
  padding: 1.4rem;
  border: 2px solid var(--color-primary);
  box-shadow: 0px 0px 10px 0px
    rgba(18.000000000000004, 185.00000000000003, 141.99999999999997, 0.52);
  border-radius: 1rem;
}

.about__card-title {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.about__card-title svg,
.about__card h2 {
  color: var(--color-primary);
}

.about__card p {
  margin-top: 1rem;
}
