.card {
  padding: 1.5rem;
  width: 330px;
  text-align: center;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  overflow: hidden;
}

.slide-in-top {
  transition: all 1.4s both;
  animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.card__inner_container h4 {
  color: #12b98e;
}

.card__image_container {
  width: 100px;
  margin: 0 auto;
}

.card__image {
  width: 100%;
}
