.service-card {
  cursor: pointer;
  width: 400px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: white;
  transition: var(--transition);
}

.service-card:hover {
  background-color: var(--color-primary);
}

.service-card:hover .service-card__title {
  color: white;
}

.service-card img {
  width: 80px;
}

.service-card__title {
  color: var(--color-primary);
}

/* tablets screens */
@media screen and (max-width: 1024px) {
 
}


/* mobiles screens */
@media screen and (max-width: 600px) {
  
}
